<template>
    <div class="container">
        <bill-list></bill-list>
        <section class="refund-apply">
            <h1>申請退款</h1>
            <div class="input-area">
                <el-input
                    v-model="refundReason"
                    type="textarea"
                    placeholder="請輸入申請退款的原因"
                ></el-input>
            </div>
            <button class="rounded-primary" @click="submitRefund">
                提交申請
            </button>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";
import billList from "../caseDetailComponents/billList.vue";
import { applyRefund } from "@/api";
export default {
    components: { billList },
    data: function () {
        return {
            refundReason: "",
        };
    },
    computed: {
        ...mapState({
            billData: (state) => state.caseDetail.billData,
        }),
    },
    methods: {
        submitRefund() {
            let data = {
                order_number: this.billData.orderNumber,
                refund_amount: this.billData.amount,
                refund_reason: this.refundReason,
            };
            applyRefund(data)
                .then((res) => {
                    this.$message({
                        type: "success",
                        message: res.message,
                    });
                    this.$router.push({
                        name: "caseDetail",
                        params: { id: this.$route.params.id },
                    });
                })
                .catch((err) => {
                    this.$message({
                        type: "error",
                        message: err.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    flex-grow: 1;
}
.refund-apply {
    @include flexc-start-stretch;
    h1 {
        margin: 0;
        padding: 16px;
        font-size: 21px;
        font-weight: $semi-bold;
        color: white;
        background-color: $green-300;
    }
    .input-area {
        padding: 16px;
    }
    .rounded-primary {
        background-color: $dark-gray;
        width: 60%;
        margin: 0 auto 16px;
    }
}
</style>