<template>
  <section class="bill-container">
    <div class="bill-items">
      <div
        :class="[item.type + '-fee']"
        v-for="item in processedBillData"
        :key="item.id"
      >
        <div class="name">
          <div>
            {{ item.title }}
          </div>
          <div class="notes" v-if="item.type === 'coupon'">
            {{ item.couponSubtitle }}
          </div>
        </div>
        <div v-if="item.type == 'coupon'" class="amount" style="color: red">
          {{ item.amount }}
        </div>
        <div v-else-if="item.type != 'coupon'" class="amount">
          {{ item.amount }}
        </div>
        <div v-else class="amount">{{ item.couponPercent }}</div>
      </div>
      <!-- <div class="main-fee">
                <div class="name">診金</div>
                <div class="amount">HKD 500</div>
            </div>
            <div class="reservation-fee">
                <div class="name">
                    <div>預約費用</div>
                    <div class="notes">*診症後將從總帳單扣除</div>
                </div>
                <div class="amount">- HKD 100</div>
            </div>
            <div class="discount-fee">
                <div class="name">
                    <div>優惠碼</div>
                    <div class="notes">每次問診只能用一個優惠碼</div>
                </div>
                <div class="amount">
                    <div>20% off</div>
                    <div class="notes">-HKD 220</div>
                </div>
            </div> -->
    </div>
    <div class="total-amt">
      <div class="name">總額</div>
      <div class="amount">$ {{ billData.amount }}</div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      billData: (state) => state.caseDetail.billData,
    }),
    processedBillData: function () {
      let result = [];
      this.billData.orderDetail.forEach((e) => {
        let type = "main";
        if (e.coupon === 1) type = "coupon";
        else if (e.deduction === 1) type = "deduction";
        let amount = "$ " + e.amount;
        if (e.deduction === 1) amount = "- " + amount;
        e.title != "總額" &&
          result.push({
            id: e.id,
            title: e.title,
            amount,
            couponPercent: e.couponPercent,
            couponSubtitle: e.couponSubtitle,
            type,
          });
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  font-size: 21px;
  font-weight: $semi-bold;
  .notes {
    font-weight: $regular;
    font-size: 12px;
  }
}
.amount {
  font-weight: $extra-bold;
  font-size: 21px;
  .notes {
    font-weight: $regular;
    font-size: 16px;
  }
}
.bill-items {
  @include flexc-start-stretch;
  padding: 16px 0 0;
  margin: 0 16px;
  border-bottom: 1px solid $black;
  .main-fee,
  .deduction-fee,
  .coupon-fee {
    @include flexr-between-start;
    margin-bottom: 16px;
  }
  .main-fee {
    color: $black;
  }
  .deduction-fee {
    color: $green-300;
    .notes {
      color: $black;
    }
  }
  .coupon-fee {
    color: #419599;
  }
}
.total-amt {
  padding: 16px;
  @include flexr-between-baseline;
}
</style>